import { FC, ReactNode } from 'react'
import { Heading } from '@instabox/consumer-web-ui'

export interface PageHeadingProps {
  children: ReactNode | undefined
}

const PageHeading: FC<PageHeadingProps> = ({ children }) => {
  return (
    <Heading fontSize="xl" color="white" align="center">
      {children}
    </Heading>
  )
}

export default PageHeading
