import { FC, ReactNode } from 'react'
import { Container, Stack, StackProps } from '@instabox/consumer-web-ui'

import styles from './pageSection.module.scss'

export interface PageSectionProps extends StackProps {
  children?: ReactNode
  backgroundColor?: string
}

const PageSection: FC<PageSectionProps> = ({ children, backgroundColor, ...stackProps }) => (
  <section style={{ backgroundColor }} className={styles.container}>
    <Container>
      <Stack {...stackProps}>{children}</Stack>
    </Container>
  </section>
)

export default PageSection
