import { getTranslation, Markdown, Texts_With_Images } from '@instabox/consumer-web-shared'
import { Card, Container, Heading, Lottie, Stack } from '@instabox/consumer-web-ui'
import cn from 'classnames'
import { HTMLMotionProps } from 'framer-motion'
import Image from 'next/legacy/image'
import { FC } from 'react'

import { ENV_CONFIG } from '../../constants/envConfig'
import InViewAnimation from '../InViewAnimation'
import styles from './textWithImage.module.scss'

export type Layout = 'inline' | 'card' | 'fullBleed'

export type TextWithImageProps = Omit<Texts_With_Images, 'status'> & {
  translations?: { [key: string]: string }[]
  layout?: Layout
  animationData: unknown
}

const TextWithImage: FC<TextWithImageProps> = ({
  image,
  animationData,
  header,
  text,
  image_placement,
  translations,
  layout = 'inline',
}) => {
  const translatedImage = translations?.[0]?.image || image

  if (layout === 'card') {
    return (
      <Card variant="secondary">
        <Stack gap="lg" className="mt-4">
          <Image
            src={getImageUrl(image.id)}
            alt={image?.title}
            width={image?.width || 50}
            height={image?.height || 50}
          />
          <Heading color="white" as="h6" align="center">
            {getTranslation('header', translations, header)}
          </Heading>
          <Markdown color="white">{getTranslation('text', translations, text)}</Markdown>
        </Stack>
      </Card>
    )
  }

  if (layout === 'inline') {
    return (
      <Card>
        <div
          className={cn(
            'row',
            'align-items-center',
            image_placement === 'right' && 'flex-row-reverse',
          )}>
          <div className="col-12 col-lg-7">
            <div className={cn(styles.inlineImage)}>
              <Image
                src={getImageUrl(translatedImage.id)}
                alt={translatedImage?.title}
                width={translatedImage?.width}
                height={translatedImage?.height}
              />
            </div>
          </div>
          <div className="col-12 col-lg-5">
            <Stack>
              <Heading color="really-red" as="h6" fontSize="xl" spacing="sm">
                {getTranslation('header', translations, header)}
              </Heading>
              <Markdown>{getTranslation('text', translations, text)}</Markdown>
            </Stack>
          </div>
        </div>
      </Card>
    )
  }

  return (
    <article className={cn(styles.container)}>
      <Container>
        <div
          className={cn(
            'row',
            'align-items-center',
            image_placement === 'right' && 'flex-row-reverse',
          )}>
          <div className="col-12 col-lg-7">
            {image && (
              <InViewAnimation
                {...contentAnimation(image_placement === 'left' ? 'ltr' : 'rtl', 0)}
                threshold={0.5}>
                <div
                  className={cn(
                    styles.image,
                    image_placement === 'left' ? styles.ltr : styles.rtl,
                  )}>
                  <Image
                    src={getImageUrl(translatedImage.id)}
                    alt={translatedImage?.title}
                    width={translatedImage?.width}
                    height={translatedImage?.height}
                  />
                </div>
              </InViewAnimation>
            )}
            {animationData && (
              <InViewAnimation
                {...contentAnimation(image_placement === 'left' ? 'ltr' : 'rtl', 0)}
                threshold={0.5}>
                <div className={styles.animation}>
                  <Lottie
                    options={{
                      animationData,
                      autoplay: true,
                      loop: true,
                    }}
                    height={490}
                    speed={0.5}
                  />
                </div>
              </InViewAnimation>
            )}
          </div>
          <div className="col-12 col-lg-5">
            <Stack>
              <InViewAnimation
                {...contentAnimation(image_placement === 'left' ? 'rtl' : 'ltr', 0)}
                threshold={0.5}>
                <Heading color="really-red" as="h6" fontSize="xl" spacing="sm">
                  {getTranslation('header', translations, header)}
                </Heading>
              </InViewAnimation>
              <InViewAnimation
                {...contentAnimation(image_placement === 'left' ? 'rtl' : 'ltr', 0.2)}>
                <Markdown>{getTranslation('text', translations, text)}</Markdown>
              </InViewAnimation>
            </Stack>
          </div>
        </div>
      </Container>
    </article>
  )
}

export default TextWithImage

const contentAnimation = (direction: 'ltr' | 'rtl', delay: number): HTMLMotionProps<'div'> => {
  return {
    variants: {
      visible: { opacity: 1, x: '0' },
      hidden: { opacity: 0, x: direction === 'ltr' ? '-100px' : '100px' },
    },
    transition: {
      duration: 0.7,
      delay,
    },
  }
}

function getImageUrl(id: string) {
  return `${ENV_CONFIG.DIRECTUS_URL}/assets/${id}`
}
