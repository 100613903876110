import { FC, ReactNode, useEffect, useRef } from 'react'
import cn from 'classnames'
import { Heroes, Unarray } from '@instabox/consumer-web-shared'

import styles from './heroImage.module.scss'

export interface HeroImageProps extends Omit<Unarray<Heroes>, 'id'> {
  children?: ReactNode | ReactNode[]
  image?: string
}

const HeroImage: FC<HeroImageProps> = ({
  children,
  image,
  background_color,
  fullscreen,
  blend_mode,
  content_position,
}) => {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    ref.current.style.setProperty('--background-image', `url(${image})`)
    ref.current.style.setProperty('--background-color', `url(${background_color})`)
  }, [image, background_color])

  return (
    <div
      className={cn(
        styles.container,
        fullscreen && styles.fullscreen,
        styles[`blendMode_${blend_mode}`],
      )}
      ref={ref}>
      <div className={cn(styles.content, styles[`content_${content_position}`])}>{children}</div>
    </div>
  )
}

export default HeroImage
