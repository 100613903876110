import { FC, useEffect } from 'react'
import { useAnimation, motion, HTMLMotionProps } from 'framer-motion'
import { useInView } from 'react-intersection-observer'

export interface InViewAnimationProps extends HTMLMotionProps<'div'> {
  threshold?: number
}

const InViewAnimation: FC<InViewAnimationProps> = ({
  children,
  threshold,
  initial = 'hidden',
  ...props
}) => {
  const controls = useAnimation()
  const [ref, inView] = useInView({
    threshold,
  })

  useEffect(() => {
    if (inView) {
      controls.start('visible')
    }
  }, [controls, inView])

  return (
    <motion.div ref={ref} animate={controls} initial={initial} {...props}>
      {children}
    </motion.div>
  )
}

export default InViewAnimation
